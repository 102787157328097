import { Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import ButtonCustom from '../../components/elements/ButtonCustom';
import { GIAM_DOC, NHAN_VIEN } from '../../helpers/roles';
import _ from 'lodash';

export const createPartnerColumn = (partnerList) => ({
  title: 'Đối tác',
  dataIndex: 'partner',
  key: 'partner',
  filters: _.map(partnerList, (item) => ({
    value: item?.id,
    text: item?.firstName,
  })),
  onFilter: (value, record) => record?.partner?.id === value,
  render: (text, record) => (
    <div className="text-sm font-semibold leading-normal">
      {record?.partner ? (
        record?.partner?.firstName
      ) : (
        <Tag color={'volcano'}>
          {'Nội bộ'.toUpperCase()}
        </Tag>
      )}
    </div>
  ),
});

export const createSentDateColumn = () => ({
  title: 'Ngày gửi ',
  dataIndex: 'sentDate',
  key: 'sentDate',
  width: 150,
  sorter: (a, b) => new Date(a.sentDate) - new Date(b.sentDate),
  render: sentDate => (
    <div>
      {sentDate && dayjs(sentDate).format('DD/MM/YYYY')}
    </div>
  ),
});

export const createCustomerNameColumn = () => ({
  title: 'Họ tên khách hàng',
  dataIndex: 'customer',
  key: 'customer',
  render: ({ isRedPoint }, record) => {
    let color = record.isRedPoint && 'text-red-500';
    return (
      <div className={`leading-normal text-4  font-semibold ${color} `}>
        {record.isRedPoint && (
          <i className="mr-1 fas fa-circle"></i>
        )}
        {record?.customer?.lastName +
          ' ' +
          record?.customer?.firstName}
      </div>
    );
  },
});

export const createAddressColumn = () => ({
  title: 'Địa chỉ khách hàng',
  dataIndex: 'customer',
  key: 'customer',
  render: (text, record) => {
    let ward = record?.customer?.ward?.name;
    let district = record?.customer?.district?.name;
    let province = record?.customer?.province?.name;
    return (
      <div className="text-sm font-semibold leading-normal">
        {ward && district && province
          ? ward + ', ' + district + ', ' + province
          : district && province
            ? district + ', ' + province
            : province
              ? province
              : district
                ? district
                : record?.customer?.detailedAddress}
      </div>
    );
  },
});

export const createCardTypeColumn = (getCardType) => ({
  title: 'Loại giấy tờ',
  dataIndex: 'cardType',
  key: 'cardType',
  render: (cardType) => getCardType(cardType),
});

export const createProductColumn = () => ({
  title: 'Loại xe',
  dataIndex: 'product',
  key: 'product',
  render: (text, record) => (
    <div className="text-sm font-semibold leading-normal">
      {record?.product?.name}
    </div>
  ),
});

export const createLicensePlateColumn = () => ({
  title: 'Biển số',
  dataIndex: 'licensePlate',
  key: 'licensePlate',
  filters: [{ text: 'Chưa nhận biển số', value: null }],
  onFilter: (value, record) => record.licensePlate === value,
  sorter: (a, b) => {
    if (a?.licensePlate && b?.licensePlate) {
      return a.licensePlate.length - b.licensePlate.length;
    } else if (a?.licensePlate) {
      return -1;
    } else if (b?.licensePlate) {
      return 1;
    }
    return 0;
  },
  render: (licensePlate) => (
    <div className="text-sm font-semibold leading-normal">
      {licensePlate}
    </div>
  ),
});

export const createDeliveryCavetDateColumn = () => ({
  title: 'Ngày nhận cavet ',
  dataIndex: 'deliveryCavetDate',
  key: 'deliveryCavetDate',
  width: 150,
  sorter: (a, b) => new Date(a.deliveryCavetDate) - new Date(b.deliveryCavetDate),
  render: (deliveryCavetDate) => (
    <div>
      {deliveryCavetDate && dayjs(deliveryCavetDate).format('DD/MM/YYYY')}
    </div>
  ),
});

export const createNoteColumn = () => ({
  title: 'Ghi chú',
  dataIndex: 'note',
  key: 'note',
  width: 150,
  render: (note) => <div>{note}</div>,
});

export const createIsPayColumn = (roles) => ({
  title: 'Tình trạng thanh toán',
  dataIndex: 'isPay',
  key: 'isPay',
  width: 150,
  filters: [{ text: 'Chưa thanh toán', value: false }, { text: 'Đã thanh toán', value: true }],
  onFilter: (value, record) => record.isPay === value,
  render: (isPay, record) => {
    if (roles[0] === GIAM_DOC || roles[0] === NHAN_VIEN) {
      if (isPay && record.paymentAmount !== 0) {
        return (
          <Tag color={'green'}>
            {record.paymentAmount !== 0
              ? `${record.paymentAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' VNĐ'
              : 0}
          </Tag>
        );
      } else {
        return <Tag color={'volcano'}>{'Chưa'.toUpperCase()}</Tag>;
      }
    }
  },
});

export const createActionColumn = (roles, handleRedpoint, paymentForPartner, internalResend, handleDelete) => ({
  title: 'Action',
  key: 'action',
  fixed: 'right',
  render: ({ isPay, servicerType, isRedPoint }, record) => (
    <div className="flex">
      <Tooltip title={isRedPoint ? 'Bỏ đánh dấu' : 'Đánh dấu khách hàng'} placement="right">
        <ButtonCustom
          onClick={(e) => handleRedpoint(e, record)}
          textColor={isRedPoint ? 'text-rose-600' : 'text-black-600'}
          icon={<i className="fa fa-map-pin"></i>}
        />
      </Tooltip>
      {(roles[0] === GIAM_DOC || roles[0] === NHAN_VIEN) && (
        <Tooltip title={!isPay ? 'Thanh toán' : 'Huỷ thanh toán'} placement="right">
          <ButtonCustom
            onClick={(e) => paymentForPartner(e, record)}
            textColor={isPay ? 'text-red-400' : 'text-green-600'}
            icon={isPay ? <i className="fas fa-window-close"></i> : <i className="fas fa-credit-card"></i>}
          />
        </Tooltip>
      )}
      {servicerType !== 1 && (
        <Tooltip title={'Gửi lại hồ sơ'} placement="right">
          <ButtonCustom
            onClick={(e) => internalResend(e, record)}
            textColor={'text-red-400'}
            icon={<i className="fas fa-undo-alt"></i>}
          />
        </Tooltip>
      )}
      <Tooltip title={'Xoá dòng này'} placement="right">
        <ButtonCustom
          onClick={(e) => handleDelete(e, record)}
          textColor={'text-red-400'}
          icon={<i className="fas fa-trash-alt"></i>}
        />
      </Tooltip>
    </div>
  ),
});


