import { Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import client from '../helpers/client';
import { convertNumberToWords } from '../helpers/utility';
import apiUrl from '../settings';
import { CARD_TYPES } from '../settings/constant';
import './invoice-css.css';

function Invoice(props) {
    const { id } = useParams();
    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(false);
    const currentDate = new Date();
    const fetchOrderDetail = async () => {
        setLoading(true);
        const { data } = await client().get(
            `${apiUrl}/ProcessingPayment/GetProcessingPaymentById/${id}`
        );
        console.log('🚀 ~ file: Report.js:14 ~ fetchOrderDetail ~ data:', data);
        if (data) {
            setOrder(data);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchOrderDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Spin spinning={loading}>
            <div
                class="c6 doc-content"
                style={{ fontFamily: 'Times New Roman' }}
            >
                <p class="c1">
                    <span class="c5 c0">CTY TNHH BA NHƯ Ý</span>
                </p>
                <p class="c1">
                    <span class="c5 c0">HOTLINE: 0565 903 888</span>
                </p>
                <p class="c1">
                    <span class="c5 c0">
                        HOTLINE: 0565 903 888 ĐỊA CHỈ: 738A, QL1A, ẤP NGŨ PHÚC,
                        HỐ NAI 3, TRẢNG BOM, ĐỒNG NAI
                    </span>
                </p>
                <p class="c1">
                    <span class="c0 c9">
                        HÓA ĐƠN GIAO GIẤY TỜ VÀ THU NỢ (nếu có)
                    </span>
                </p>
                <p class="c3">
                    <span class="c2">
                        Ngày {currentDate.getDate()} tháng{' '}
                        {currentDate.getMonth() + 1} năm{' '}
                        {currentDate.getFullYear()}
                    </span>
                </p>
                <p class="c3">
                    <span>Họ và tên: </span>
                    <span class="c0">{order?.customerName}</span>
                </p>
                <p class="c3">
                    <span>Địa chỉ: </span>
                    <span class="c0">{order?.customerDetailedAddress}</span>
                </p>
                <p class="c3">
                    <span>Xe: </span>
                    <span class="c0">
                        {order?.orderOrderDetail[0].product.name}
                    </span>
                    <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;số
                        khung:
                    </span>
                    <span class="c0 c5">
                        {order?.orderOrderDetail[0].product.frameNumber}
                    </span>{' '}
                    <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Biển số:
                    </span>
                    <span class="c0 c5">{order?.licensePlate}</span>
                </p>
                <p class="c3">
                    <span>Số tiền thanh toán: </span>
                    <span class="c0">
                        {' '}
                        {order?.paymentAmount !== 0
                            ? `${order?.paymentAmount}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                              ) + ' VNĐ'
                            : 0}
                    </span>
                    <span class="c2">&nbsp;</span>
                </p>
                <p class="c3">
                    <span>Nội dung cửa hàng: </span>
                    <span class="c5 c0">{order?.note}</span>
                </p>
                <p class="c3">
                    <span class="c0 c8">
                        Còn nợ lại:
                        {order?.orderOrderDetail[0].debt !== 0
                            ? `${order?.orderOrderDetail[0].debt}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                              ) + ' VNĐ'
                            : 0}
                    </span>
                </p>
                <p class="c3">
                    <span class="c4">
                        Người thanh toán/Người nhận giấy tờ (ký xác nhận):
                    </span>
                </p>
                <p class="c3 c7" id="h.gjdgxs">
                    <span class="c2"></span>
                </p>
                <p class="c3 c7">
                    <span class="c2"></span>
                </p>
                <p class="c3 c7">
                    <span class="c2"></span>
                </p>
                <p class="c3">
                    <span class="c2">
                        ------------------------------------------------------------------------------------------------
                    </span>
                </p>
                <p class="c1">
                    <span class="c5 c0">CTY TNHH BA NHƯ Ý</span>
                </p>
                <p class="c1">
                    <span class="c5 c0">HOTLINE: 0565 903 888</span>
                </p>
                <p class="c1">
                    <span class="c5 c0">
                        HOTLINE: 0565 903 888 ĐỊA CHỈ: 738A, QL1A, ẤP NGŨ PHÚC,
                        HỐ NAI 3, TRẢNG BOM, ĐỒNG NAI
                    </span>
                </p>
                <p class="c1">
                    <span class="c9 c0">
                        HÓA ĐƠN GIAO GIẤY TỜ VÀ THU NỢ (nếu có)
                    </span>
                </p>
                <p class="c3">
                    <span class="c2">
                        Ngày {currentDate.getDate()} tháng{' '}
                        {currentDate.getMonth() + 1} năm{' '}
                        {currentDate.getFullYear()}
                    </span>
                </p>
                <p class="c3">
                    <span>Họ và tên: </span>
                    <span class="c0">{order?.customerName}</span>
                </p>
                <p class="c3">
                    <span>Địa chỉ: </span>
                    <span class="c0">{order?.customerDetailedAddress}</span>
                </p>
                <p class="c3">
                    <span>Xe: </span>
                    <span class="c0">
                        {order?.orderOrderDetail[0].product.name}
                    </span>
                    <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;số
                        khung:
                    </span>
                    <span class="c0 c5">
                        {order?.orderOrderDetail[0].product.frameNumber}
                    </span>{' '}
                    <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Biển số:
                    </span>
                    <span class="c0 c5">{order?.licensePlate}</span>
                </p>
                <p class="c3">
                    <span>Số tiền thanh toán: </span>
                    <span class="c0">
                        {' '}
                        {order?.paymentAmount !== 0
                            ? `${order?.paymentAmount}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                              ) + ' VNĐ'
                            : 0}
                    </span>
                    <span class="c2">&nbsp;</span>
                </p>
                <p class="c3">
                    <span>Nội dung cửa hàng: </span>
                    <span class="c5 c0">{order?.note}</span>
                </p>
                <p class="c3">
                    <span class="c0 c8">
                        Còn nợ lại:
                        {order?.orderOrderDetail[0].debt !== 0
                            ? `${order?.orderOrderDetail[0].debt}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                              ) + ' VNĐ'
                            : 0}
                    </span>
                </p>
                <p class="c3">
                    <span class="c4">
                        Người thanh toán/Người nhận giấy tờ (ký xác nhận):
                    </span>
                </p>
                <p class="c3 c7">
                    <span class="c2"></span>
                </p>
                <p class="c3 c7">
                    <span class="c2"></span>
                </p>
                <p class="c3 c7">
                    <span class="c2"></span>
                </p>
            </div>
        </Spin>
    );
}

Invoice.propTypes = {};

export default Invoice;
